import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import styles from './Installation.module.css';
import config from '../../config';

const TIMEOUT_DAYS = 5;

export const Installation = () => {
	const { show, onCloseClick } = useInstallationDialog();
	if (!show) {
		return null;
	}
	return (
		<div className={styles['installation-dialog']}>
			<div className={styles['container']}>
				<div className={styles['close-container']}>
					<CloseBtn onClick={onCloseClick} />
				</div>
				<div className={styles['content']}>
					<div>
						<img src="/assets/manifest-icon-512.png" alt="logo" />
						<h3>
							Install <i>{config.title}</i>
						</h3>
						<p>
							Install this application on your home screen for
							quick and easy access when you are on the go.
						</p>
					</div>
					<div>
						Just tap <Share /> icon and Add to Home Screen
					</div>
				</div>
			</div>
		</div>
	);
};

Installation.propTypes = {
	show: PropTypes.bool.isRequired,
	onCloseClick: PropTypes.object.isRequired,
};

export function useInstallationDialog() {
	const [show, setShow] = useState(false);
	const standalone = isStandAlone();
	const key = 'last-time-installation';
	const onCloseClick = () => {
		setShow(false);
	};
	useEffect(() => {
		if (standalone) {
			return;
		}
		const ts = localStorage.getItem(key);
		if (!ts) {
			localStorage.setItem(key, new Date().toJSON());
			setShow(true);
			return;
		}
		const lastTime = moment(new Date(ts));
		const now = moment(new Date());

		const duration = moment.duration(now.diff(lastTime));
		if (duration.asDays() < TIMEOUT_DAYS) {
			setShow(false);
			return;
		}

		localStorage.setItem(key, new Date().toJSON());
		setShow(true);
	}, []);
	return {
		show,
		onCloseClick,
	};
}

export function isStandAlone() {
	const navigator = window?.navigator;
	if (navigator.standalone) {
		return true;
	}
	return window.matchMedia('(display-mode: standalone)').matches;
}

function CloseBtn({ onClick }) {
	return (
		<button type="button" title="Close" onClick={onClick}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			>
				<line x1="18" y1="6" x2="6" y2="18"></line>
				<line x1="6" y1="6" x2="18" y2="18"></line>
			</svg>
		</button>
	);
}

CloseBtn.propTypes = {
	onClick: PropTypes.object.isRequired,
};

function Share() {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
			<polyline points="16 6 12 2 8 6"></polyline>
			<line x1="12" y1="2" x2="12" y2="15"></line>
		</svg>
	);
}

export function InstallBtn({ onClick }) {
	if (isStandAlone()) {
		return null;
	}
	return (
		<button
			type="button"
			className={styles['install-btn ']}
			onClick={onClick}
		>
			Install
		</button>
	);
}

InstallBtn.propTypes = {
	onClick: PropTypes.object.isRequired,
};
