const config = {
    "title": "Ronda",
    "appKey": "7a1761e5-55bd-4bb3-b45b-f5e306012343",
    "stripeKey": "pk_test_W955SArBKQSl0oJiqvTy1RpK002fHGREqC",
    "host": "https://graphql.canvasflow.io",
    "theme": {
        "toc": {
            "background": "#f8f8f8",
            "color": "#000000",
            "selectedColor": "#000000",
            "textTransform": "none",
            "listStyle": "none",
            "fontFamily": "",
            "selectedFontFamily": "",
            "thumbnails": {
                "enabled": true
            }
        },
        "color": "#ffffff",
        "background": "#ffffff",
        "loading": {
            "color": "#333333",
            "type": false
        },
        "progressBar": {
            "background": "#ffffff",
            "color": "#666666",
            "enabled": true,
            "height": 5
        },
        "navbar": {
            "height": 50,
            "background": "#ffffff",
            "color": "#333333",
            "slider": {
                "background": "#ededed",
                "accentColor": "#b9b9b9",
                "color": "#b9b9b9",
                "knob": {
                    "size": 25,
                    "color": "#000000"
                }
            },
            "toc": {
                "color": "#666666",
                "selectedColor": "#333333",
                "textTransform": "none",
                "listStyle": "none",
                "fontFamily": "BarlowCondensed-Light",
                "selectedFontFamily": "BarlowCondensed-bold"
            }
        },
        "buttons": {
            "primary": {
                "color": "#ffffff",
                "background": "#28a745"
            },
            "secondary": {
                "color": "#ffffff",
                "background": "#6c757d"
            }
        },
        "ribbons": {
            "display": false
        },
        "issues": {
            "title": {
                "display": true
            }
        },
        "bottomBar": {
            "background": "#343a40",
            "accentColor": "#f8f9fa",
            "color": "#ffffff",
            "slider": {
                "background": "#555555",
                "accentColor": "#23ad21",
                "color": "#555555",
                "knob": {
                    "size": 25,
                    "color": "#000000"
                }
            }
        }
    },
    "version": "1.27.35",
    "auth": {
        "google": true,
        "apple": true,
        "email": true
    },
    "password": {
        "enabled": false,
        "value": ""
    },
    "rssFeed": {
        "enabled": false
    },
    "search": {
        "enabled": false
    },
    "lastLocation": {
        "enabled": false
    },
    "tts": {
        "enabled": false
    },
    "admin": {
        "enabled": true
    },
    "iframe": {
        "enabled": false
    },
    "bookmarks": {
        "enabled": false
    },
    "readTime": {
        "enabled": false
    },
    "indexing": {
        "enabled": false
    },
    "share": {
        "enabled": true
    },
    "subdirectory": "",
    "contentProtection": {
        "enabled": false
    },
    "subscriptionExpirationDate": {
        "enabled": false,
        "salt": 7
    },
    "navArrows": {
        "enabled": true,
        "mode": "automatic"
    },
    "fontSizeOption": {
        "enabled": true
    },
    "defaultLanguage": "es",
    "languages": [
        "en",
        "es"
    ],
    "analytics": {
        "tealium": {
            "ChannelID": "",
            "pageType": "",
            "pageDetail": "",
            "pageTemplate": "",
            "projectType": "",
            "url": ""
        },
        "google": {
            "trackerID": "",
            "sampleRate": 1
        },
        "ga4": {
            "trackerID": "",
            "sampleRate": 1
        },
        "gtm": {
            "trackerID": ""
        },
        "googleOptimize": {
            "trackerID": ""
        },
        "onetrust": {
            "id": ""
        },
        "caymland": {
            "domain": ""
        },
        "ackee": {
            "domainId": "",
            "server": ""
        },
        "netstats": {
            "trackerID": ""
        },
        "hotjar": {
            "trackerID": ""
        },
        "hubspot": {
            "trackerID": ""
        },
        "ShareThis": {
            "trackerID": ""
        },
        "cookiefirst": {
            "key": ""
        },
        "cookiehub": {
            "trackerID": ""
        },
        "plausible": {
            "domain": ""
        },
        "matomo": {
            "domain": ""
        }
    },
    "contentType": "responsive-replica",
    "downloadReplicaPage": {
        "enabled": false
    },
    "replica": {
        "zoom": {
            "enabled": true,
            "increment": 170
        }
    },
    "buildAt": 1704272910980
};
window.appKey = '7a1761e5-55bd-4bb3-b45b-f5e306012343';
window.version = '1.27.35';
window.config = config;
export default config;