const PT_TO_EM_FACTOR = 1 / 10.111;
const PT_TO_EM_FACTOR_LINE_HEIGHT = 1 / 10.111;
export class CSS {
	constructor(id, styles, StyleId, onCustomCSS) {
		this.id = id;
		this.styles = clone(styles);
		const style = clone(styles.get(`${StyleId}`));

		this.style = clone(style);
		if (style.tablet && this.styles.get(`${style.tablet}`)) {
			this.tabletStyle = clone(this.styles.get(`${style.tablet}`));
		}
		if (style.desktop && this.styles.get(`${style.desktop}`)) {
			this.desktopStyle = clone(this.styles.get(`${style.desktop}`));
		}

		this.onCustomCSS = onCustomCSS;
	}

	async build() {
		const css = [];
		if (!this.style) {
			return '';
		}

		css.push(
			await this.mapDeviceStyle({
				style: clone(this.style),
				device: 'mobile',
			})
		);
		if (this.tabletStyle) {
			css.push(
				await this.mapDeviceStyle({
					style: clone(this.tabletStyle),
					device: 'tablet',
				})
			);
		}
		if (this.desktopStyle) {
			css.push(
				await this.mapDeviceStyle({
					style: clone(this.desktopStyle),
					device: 'desktop',
				})
			);
		}

		return css.join('\n');
	}

	mapDeviceStyle = async ({ style, device }) => {
		style = clone(style);
		const { paddingLeft, paddingRight } = this.getBleedPadding(style);
		const canvas = this.getCanvasCSS(
			clone(style),
			paddingLeft,
			paddingRight,
			device
		);

		const bleed = this.getBleedCSS(paddingLeft, paddingRight, device);
		const caption = this.getCaptionCSS(clone(style), device);
		const credit = this.getCreditCSS(clone(style), device);
		const text = await this.getTextCSS(clone(style), device);
		const anchor = this.getAnchorCSS(clone(style), device);
		const spacer = this.getSpacerCSS(clone(style), device);
		const buttons = this.getButtonsCSS(clone(style), device);
		const tabs = this.getTabsCSS(clone(style), device);
		let styles = [
			canvas,
			bleed,
			caption,
			credit,
			text,
			anchor,
			spacer,
			buttons,
			tabs,
		];
		const media = ['image', 'gallery', 'video'];
		for (const mediaType of media) {
			styles.push(this.getMediaCSS(style, mediaType));
		}

		const css = styles.filter((i) => i).join('\n');
		switch (device) {
			case 'tablet':
				return `@media only screen and (min-width:767px) and (max-width:1200px) {
				  ${css}
				}`;
			case 'desktop':
				return `@media only screen and (min-width: 1201px) {
				  ${css}
				}`;
			default:
				return css;
		}
	};

	getBleedPadding = (style) => {
		if (!style?.properties['canvas']) {
			return {
				paddingLeft: '0px',
				paddingRight: '0px',
			};
		}
		let { paddingLeft, paddingRight, unit } = style.properties['canvas'];

		return {
			paddingLeft: paddingLeft
				? `${paddingLeft}${unit['paddingLeft']}`
				: '0px',
			paddingRight: paddingRight
				? `${paddingRight}${unit['paddingRight']}`
				: '0px',
		};
	};

	getCanvasCSS = (style, paddingLeft, paddingRight, device) => {
		if (!style?.properties['canvas']) {
			return '';
		}
		const id = this.id;

		const properties = clone(style.properties['canvas']);

		let { bodyColor, backgroundColor, paddingTop, paddingBottom, unit } =
			properties;

		const css = [];

		bodyColor = bodyColor
			? `background-color: #${bodyColor.replace('#', '')};`
			: '';
		backgroundColor = backgroundColor
			? `background-color: #${backgroundColor.replace('#', '')};`
			: '';
		paddingTop =
			paddingTop !== undefined
				? `padding-top: ${paddingTop}${unit['paddingTop']};`
				: '';
		paddingBottom =
			paddingBottom !== undefined
				? `padding-bottom: ${paddingBottom}${unit['paddingBottom']};`
				: '';
		paddingLeft =
			paddingLeft !== undefined ? `padding-left: ${paddingLeft};` : '';
		paddingRight =
			paddingRight !== undefined ? `padding-right: ${paddingRight};` : '';

		css.push(`.article-${id}${device === 'mobile' ? '' : `.${device}`}{
			${bodyColor}
		}`);
		css.push(`.article-${id} .canvas {
			display: block;
			height: 100%; 
		}`);
		css.push(`.article-${id}${
			device === 'mobile' ? '' : `.${device}`
		} > .canvas > :first-child {
			${backgroundColor}
			${paddingLeft}
			${paddingRight}
			${paddingTop}
			${paddingBottom}
		}`);

		return css.join('\n');
	};

	getBleedCSS = (paddingLeft, paddingRight, device) => {
		const id = this.id;
		device = device !== 'mobile' ? `.${device}` : '';
		// const bleedLeft = paddingLeft ? `margin-left: calc(-${paddingLeft} - ${paddingRight});` : '';
		return `.article-${id}${device} > .canvas .bleed {
			margin-left: -${paddingLeft} !important;
			margin-right: -${paddingRight} !important;
			width: calc(100% + ${paddingLeft} + ${paddingRight});
			max-width: unset;
		}
		
		.article-${id}${device} > .canvas .bleed-left {
			margin-left: -${paddingLeft} !important;
			width: calc(100% + ${paddingLeft});
			max-width: unset;
		}
		
		.article-${id}${device} > .canvas .bleed-right {
			margin-right: -${paddingRight} !important;
			width: calc(100% + ${paddingRight});
			
			max-width: unset;
		}

		.article-${id}${device} > .canvas .bleed .columns {
			margin-left: -${paddingLeft};
			margin-right: -${paddingRight};
			width: calc(100% + ${paddingLeft} + ${paddingRight});
			max-width: unset;
			padding-left: ${paddingLeft};
			padding-right: ${paddingRight};
		}
		
		.article-${id}${device} > .canvas .bleed-left .columns {
			margin-left: -${paddingLeft};
			width: calc(100% + ${paddingLeft});
			max-width: unset;
			padding-left: ${paddingLeft};
		}
		
		.article-${id}${device} > .canvas .bleed-right .columns {
			margin-right: -${paddingRight};
			width: calc(100% + ${paddingRight});
			max-width: unset;
			padding-right: ${paddingRight};
		}
		`;

		/*return `.article-${id}${device} > .canvas .bleed {
			${bleedLeft}
			background: blue;
			width: calc(100% + (${paddingLeft} * 2) + (${paddingRight} * 2)) !important;
		}`;*/
	};

	getMediaCSS = (style, type) => {
		const id = this.id;
		const canvasStyle = style.properties['canvas'] || {};
		let css = [];

		if (
			canvasStyle.paddingLeft !== undefined &&
			canvasStyle.unit['paddingLeft']
		) {
			css.push(`.article-${id} .${type}.bleed figcaption,
				.article-${id} .${type}.bleed-left figcaption,
				.article-${id} .${type}.bleed cite,
				.article-${id} .${type}.bleed-left cite {
				padding-left: ${canvasStyle.paddingLeft}${canvasStyle.unit['paddingLeft']};
			}`);
		}

		if (
			canvasStyle.paddingRight !== undefined &&
			canvasStyle.unit['paddingRight']
		) {
			css.push(`.article-${id} .${type}.bleed figcaption,
				.article-${id} .${type}.bleed-right figcaption,
				.article-${id} .${type}.bleed cite,
				.article-${id} .${type}.bleed-right cite {
				padding-right: ${canvasStyle.paddingRight}${canvasStyle.unit['paddingRight']};
			}`);
		}

		if (!style?.properties[type]) {
			return css.join('\n');
		}

		const properties = clone(style.properties[type]);
		let {
			marginTop,
			marginBottom,
			marginLeft,
			marginRight,
			paddingLeft,
			paddingRight,
			paddingTop,
			paddingBottom,
			unit,
		} = properties;

		marginTop =
			marginTop !== undefined
				? `margin-top: ${marginTop}${unit['marginTop']};`
				: '';
		marginBottom =
			marginBottom !== undefined
				? `margin-bottom: ${marginBottom}${unit['marginBottom']};`
				: '';
		marginLeft =
			marginLeft !== undefined
				? `margin-left: ${marginLeft}${unit['marginLeft']};`
				: '';
		marginRight =
			marginRight !== undefined
				? `margin-right: ${marginRight}${unit['marginRight']};`
				: '';
		paddingTop =
			paddingTop !== undefined
				? `padding-top: ${paddingTop}${unit['paddingTop']};`
				: '';
		paddingBottom =
			paddingBottom !== undefined
				? `padding-bottom: ${paddingBottom}${unit['paddingBottom']};`
				: '';
		paddingLeft =
			paddingLeft !== undefined
				? `padding-left: ${paddingLeft}${unit['paddingLeft']};`
				: '';
		paddingRight =
			paddingRight !== undefined
				? `padding-right: ${paddingRight}${unit['paddingRight']};`
				: '';

		css.push([
			`.article-${id} .${type} {
				${marginTop}
				${marginBottom}
				${marginLeft}
				${marginRight}
				${paddingTop}
				${paddingBottom}
				${paddingLeft}
				${paddingRight}
			}`,
		]);
		return css.join('\n');
	};

	getCaptionCSS = (style, device) => {
		const id = this.id;
		device = device !== 'mobile' ? `.${device}` : '';
		if (!style?.properties?.imagecaption) {
			return '';
		}
		let {
			color,
			fontFamily,
			fontSize,
			fontStyle,
			fontWeight,
			letterSpacing,
			lineHeight,
			marginBottom,
			marginTop,
			marginLeft,
			marginRight,
			paddingTop,
			paddingBottom,
			paddingLeft,
			paddingRight,
			textAlign,
			textDecoration,
			textTransform,
			unit,
		} = style.properties['imagecaption'];

		color = color ? `color: #${color.replace('#', '')};` : '';
		fontFamily = fontFamily ? `font-family: ${fontFamily};` : '';
		if (fontSize && unit && unit['fontSize'] === 'pt') {
			fontSize = fontSize
				? `font-size: ${fontSize * PT_TO_EM_FACTOR}em;`
				: '';
		} else {
			fontSize = fontSize
				? `font-size: ${fontSize}${unit['fontSize']};`
				: '';
		}

		fontStyle = fontStyle ? `font-style: ${fontStyle};` : '';
		fontWeight = fontWeight ? `font-weight: ${fontWeight};` : '';
		if (letterSpacing && unit && unit['letterSpacing'] === 'pt') {
			letterSpacing =
				letterSpacing !== undefined
					? `letter-spacing: ${
							parseFloat(`${letterSpacing}`) * PT_TO_EM_FACTOR
					  }em;`
					: '';
		} else {
			letterSpacing =
				letterSpacing !== undefined
					? `letter-spacing: ${parseFloat(letterSpacing)}${
							unit['letterSpacing']
					  };`
					: '';
		}

		if (lineHeight && unit && unit['lineHeight'] === 'pt') {
			lineHeight =
				lineHeight !== undefined
					? `line-height: ${
							(lineHeight * PT_TO_EM_FACTOR_LINE_HEIGHT) / 1.6
					  }rem;`
					: '';
		} else {
			lineHeight =
				lineHeight !== undefined
					? `line-height: ${lineHeight}${unit['lineHeight']};`
					: '';
		}

		marginBottom =
			marginBottom !== undefined
				? `margin-bottom: ${marginBottom}${unit['marginBottom']};`
				: '';
		marginTop =
			marginTop !== undefined
				? `margin-top: ${marginTop}${unit['marginBottom']};`
				: '';
		marginLeft =
			marginLeft !== undefined
				? `margin-left: ${marginLeft}${unit['marginLeft']};`
				: '';
		marginRight =
			marginRight !== undefined
				? `margin-right: ${marginRight}${unit['marginRight']};`
				: '';
		paddingTop =
			paddingTop !== undefined
				? `padding-top: ${paddingTop}${unit['paddingTop']};`
				: '';
		paddingBottom =
			paddingBottom !== undefined
				? `padding-bottom: ${paddingBottom}${unit['paddingBottom']};`
				: '';
		paddingLeft =
			paddingLeft !== undefined
				? `padding-left: ${paddingLeft}${unit['paddingLeft']};`
				: '';
		paddingRight =
			paddingRight !== undefined
				? `padding-right: ${paddingRight}${unit['paddingRight']};`
				: '';
		textAlign = textAlign ? `text-align: ${textAlign};` : '';
		textDecoration = textDecoration
			? `text-decoration: ${textDecoration};`
			: '';
		textTransform = textTransform
			? `text-transform: ${textTransform};`
			: '';

		return `.article-${id}${device} > .canvas figure figcaption,
		.article-${id}${device} > .canvas .gallery figcaption,
		.article-${id}${device} > .canvas .video figcaption {
			${color}
			${fontFamily}
			${fontSize}
			${fontStyle}
			${fontWeight}
			${letterSpacing}
			${lineHeight}
			${textAlign}
			${textDecoration}
			${textTransform}
			${marginTop}
			${marginBottom}
			${marginLeft}
			${marginRight}
			${paddingTop}
			${paddingLeft}
			${paddingRight}
			${paddingBottom}
		}`;
	};

	getCreditCSS = (style, device) => {
		const id = this.id;
		device = device !== 'mobile' ? `.${device}` : '';
		if (!style?.properties?.credit) {
			return '';
		}
		let {
			width,
			color,
			fontFamily,
			fontSize,
			fontStyle,
			fontWeight,
			letterSpacing,
			lineHeight,
			textAlign,
			textDecoration,
			textTransform,
			marginBottom,
			marginTop,
			marginLeft,
			marginRight,
			paddingTop,
			paddingBottom,
			paddingLeft,
			paddingRight,
			unit,
		} = style.properties['credit'];

		// TODO Add support for unit
		width = width ? `width: ${width}%;` : '';
		color = color ? `color: #${color.replace('#', '')};` : '';
		fontFamily = fontFamily ? `font-family: ${fontFamily};` : '';
		if (fontSize && unit && unit['fontSize'] === 'pt') {
			fontSize = fontSize
				? `font-size: ${fontSize * PT_TO_EM_FACTOR}em;`
				: '';
		} else {
			fontSize = fontSize
				? `font-size: ${fontSize}${unit['fontSize']};`
				: '';
		}
		fontStyle = fontStyle ? `font-style: ${fontStyle};` : '';
		fontWeight = fontWeight ? `font-weight: ${fontWeight};` : '';
		if (letterSpacing && unit && unit['letterSpacing'] === 'pt') {
			letterSpacing =
				letterSpacing !== undefined
					? `letter-spacing: ${
							parseFloat(letterSpacing) * PT_TO_EM_FACTOR
					  }em;`
					: '';
		} else {
			letterSpacing =
				letterSpacing !== undefined
					? `letter-spacing: ${parseFloat(letterSpacing)}${
							unit['letterSpacing']
					  };`
					: '';
		}
		if (lineHeight && unit && unit['lineHeight'] === 'pt') {
			lineHeight =
				lineHeight !== undefined
					? `line-height: ${lineHeight * PT_TO_EM_FACTOR}em;`
					: '';
		} else {
			lineHeight =
				lineHeight !== undefined
					? `line-height: ${lineHeight}${unit['lineHeight']};`
					: '';
		}
		marginBottom =
			marginBottom !== undefined
				? `margin-bottom: ${marginBottom}${unit['marginBottom']};`
				: '';
		marginTop =
			marginTop !== undefined
				? `margin-top: ${marginTop}${unit['marginBottom']};`
				: '';
		marginLeft =
			marginLeft !== undefined
				? `margin-left: ${marginLeft}${unit['marginLeft']};`
				: '';
		marginRight =
			marginRight !== undefined
				? `margin-right: ${marginRight}${unit['marginRight']};`
				: '';
		paddingTop =
			paddingTop !== undefined
				? `padding-top: ${paddingTop}${unit['paddingTop']};`
				: '';
		paddingBottom =
			paddingBottom !== undefined
				? `padding-bottom: ${paddingBottom}${unit['paddingBottom']};`
				: '';
		paddingLeft =
			paddingLeft !== undefined
				? `padding-left: ${paddingLeft}${unit['paddingLeft']};`
				: '';
		paddingRight =
			paddingRight !== undefined
				? `padding-right: ${paddingRight}${unit['paddingRight']};`
				: '';
		textAlign = textAlign ? `text-align: ${textAlign};` : '';
		textDecoration = textDecoration
			? `text-decoration: ${textDecoration};`
			: '';
		textTransform = textTransform
			? `text-transform: ${textTransform};`
			: '';

		return `.article-${id}${device} > .canvas figure cite, 
		.article-${id}${device} > .canvas .gallery cite,
		.article-${id}${device} > .canvas .video cite {
			width: 100%;
			${width}
			${color}
			${fontFamily}
			${fontSize}
			${fontStyle}
			${fontWeight}
			${letterSpacing}
			${lineHeight}
			${marginBottom}
			${marginTop}
			${marginLeft}
			${marginRight}
			${paddingTop}
			${paddingLeft}
			${paddingRight}
			${paddingBottom}
			${textAlign}
			${textDecoration}
			${textTransform}
		}`;
	};

	getButtonsCSS = (style, device) => {
		const id = this.id;
		const css = [''];
		device = device !== 'mobile' ? `.${device}` : '';
		// TODO Add support for unit
		if (style?.properties?.buttons?.length) {
			for (const properties of style.properties.buttons) {
				let {
					align,
					backgroundColor,
					color,
					fontFamily,
					fontSize,
					letterSpacing,
					lineHeight,
					marginBottom,
					marginTop,
					unit,
				} = properties;

				align = align ? `text-align: ${align};` : '';
				backgroundColor = backgroundColor
					? `background: #${backgroundColor.replace('#', '')};`
					: '';
				fontFamily = fontFamily ? `font-family: ${fontFamily};` : '';
				if (fontSize && unit && unit['fontSize'] === 'pt') {
					fontSize = fontSize
						? `font-size: ${fontSize * PT_TO_EM_FACTOR}em;`
						: '';
				} else {
					fontSize = fontSize
						? `font-size: ${fontSize}${unit['fontSize']};`
						: '';
				}
				color = color ? `color: #${color.replace('#', '')};` : '';
				if (lineHeight && unit && unit['lineHeight']) {
					lineHeight =
						lineHeight !== undefined
							? `line-height: ${lineHeight * PT_TO_EM_FACTOR}em;`
							: '';
				} else {
					lineHeight =
						lineHeight !== undefined
							? `line-height: ${lineHeight}${unit['lineHeight']};`
							: '';
				}

				letterSpacing =
					letterSpacing !== undefined
						? `letter-spacing: ${parseFloat(letterSpacing)}${
								unit['letterSpacing']
						  };`
						: '';
				marginTop =
					marginTop !== undefined
						? `margin-top: ${marginTop}${unit['marginTop']};`
						: '';
				marginBottom =
					marginBottom !== undefined
						? `margin-bottom: ${marginBottom}${unit['marginBottom']};`
						: '';
				let textTransform = properties['case']
					? `text-transform: ${properties['case']};`
					: '';

				css.push(`.article-${id}${device}  > .canvas .${properties.id} {
					cursor: pointer;
					border: none;
					box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16), 0 1px 1px rgba(0, 0, 0, 0.12);
					border-radius: 4px;
					${align}
					${backgroundColor}
					${fontFamily}
					${fontSize}
					${color}
					${lineHeight}
					${letterSpacing}
					${marginBottom}
					${marginTop}
					${textTransform}
				}`);
			}
		}

		return css.join('\n');
	};

	getTabsCSS = (style, device) => {
		const id = this.id;
		const css = [''];
		device = device !== 'mobile' ? `.${device}` : '';
		if (style?.properties?.tabs) {
			const properties = style.properties.tabs;

			let {
				fontFamily,
				fontSize,
				letterSpacing,
				tabTextColor,
				tabSelectedTextColor,
				tabSelectedHighlightColor,
				tabSelectedBackgroundColor,
				tabBackgroundColor,
				tabContentTopMargin,
				tabPaddingVertical,
				tabPaddingHorizontal,
				unit,
			} = properties;

			fontFamily = fontFamily ? `font-family: ${fontFamily};` : '';
			fontSize = '';
			tabPaddingHorizontal = '';
			tabPaddingVertical = '';
			letterSpacing = '';
			tabContentTopMargin = '';
			if (unit) {
				if (fontSize && unit['fontSize'] === 'pt') {
					fontSize = fontSize
						? `font-size: ${fontSize * PT_TO_EM_FACTOR}em;`
						: '';
				} else {
					fontSize = fontSize
						? `font-size: ${fontSize}${unit['fontSize']};`
						: '';
				}
				tabPaddingHorizontal = tabPaddingHorizontal
					? `padding-left: ${tabPaddingHorizontal}${unit['tabPaddingHorizontal']}; 
					padding-right: ${tabPaddingHorizontal}${unit['tabPaddingHorizontal']};`
					: null;
				tabPaddingVertical = tabPaddingVertical
					? `padding-top: ${tabPaddingVertical}${unit['tabPaddingVertical']}; 
					padding-bottom: ${tabPaddingVertical}${unit['tabPaddingVertical']};`
					: null;
				letterSpacing = letterSpacing
					? `letter-spacing: ${parseFloat(letterSpacing)}${
							unit['letterSpacing']
					  };`
					: '';
				tabContentTopMargin = tabContentTopMargin
					? `padding-top: ${tabContentTopMargin}${unit['tabContentTopMargin']};`
					: '';
			}

			tabTextColor = tabTextColor
				? `color: #${tabTextColor.replace('#', '')};`
				: '';
			tabBackgroundColor = tabBackgroundColor
				? `background: #${tabBackgroundColor.replace('#', '')};`
				: '';
			tabSelectedTextColor = tabTextColor
				? `color: #${tabSelectedTextColor.replace('#', '')};`
				: '';
			tabSelectedHighlightColor = tabSelectedHighlightColor
				? `border-bottom: 1px solid #${tabSelectedHighlightColor.replace(
						'#',
						''
				  )};`
				: '';
			tabSelectedBackgroundColor = tabSelectedBackgroundColor
				? `background: #${tabSelectedBackgroundColor.replace('#', '')};`
				: '';

			if (tabContentTopMargin) {
				css.push(`.article-${id}${device}  > .canvas .tabs .content {
					${tabContentTopMargin}
				}`);
			}

			css.push(`.article-${id}${device}  > .canvas .tab {
				${fontFamily}
				${fontSize}
				${letterSpacing}
				${tabTextColor}
				${tabBackgroundColor}
				${tabPaddingVertical}
				${tabPaddingHorizontal}
			}`);
			css.push(`.article-${id}${device}  > .canvas .tab.selected {
				${tabSelectedTextColor}
				${tabSelectedBackgroundColor}
				${tabSelectedHighlightColor}
			}`);
		}

		return css.join('\n');
	};

	getTextCSS = async (style, device) => {
		const id = this.id;

		style = clone(style);
		device = device !== 'mobile' ? `.${device}` : '';
		const css = [''];
		let textComponents = new Set([
			'headline',
			'title',
			'subtitle',
			'intro',
			'body',
			'crosshead',
			'byline',
			'blockquote',
			'footer',
			'imagecaption',
		]);

		for (let i = 0; i <= 40; i++) {
			textComponents.add(`text${i + 1}`);
		}

		// textComponents = [...textComponents];

		// Mobile
		for (const component in style.properties) {
			// const properties = style.properties[property];
			// The component is not a text so we ignore it
			if (!textComponents.has(component)) {
				continue;
			}

			const properties = clone(style.properties[component]);

			if (component === 'text28' && `${id}` === '22266') {
				console.log(properties);
			}

			let {
				fontFamily,
				fontSize,
				color,
				fontWeight,
				fontStyle,
				textDecoration,
				lineHeight,
				textTransform,
				textAlign,
				letterSpacing,
				textDisplay,
				textIndent,
				contentAlign,
				width,
				// Dropcap
				dropcapFontSize,
				dropcapLineHeight,
				dropcapColor,
				dropcapBackgroundColor,
				dropcapFontFamily,
				dropcapPaddingRight,
				dropcapPaddingLeft,
				dropcapPaddingTop,
				dropcapPaddingBottom,
				dropcapMarginTop,
				dropcapMarginBottom,
				dropcapMarginLeft,
				dropcapMarginRight,
				// Font Weight
				fontFamilyBold,
				fontFamilyItalic,
				fontFamilyBoldItalic,
				// Background color
				backgroundColor,
				// Margin
				marginBottom,
				marginTop,
				marginLeft,
				marginRight,
				paragraphSpacing,
				// Padding
				paddingTop,
				paddingBottom,
				paddingLeft,
				paddingRight,
				// Border
				borderType,
				borderEdge,
				borderWidth,
				borderColor,
				borderRadius,
				// Anchor
				anchor_color,
				anchor_background_color,
				anchor_font_style,
				anchor_font_weight,
				anchor_text_decoration,
				anchor_text_decoration_color,
				// Unit
				unit,
			} = clone(properties);

			if (
				textAlign === 'center' &&
				textDisplay === 'inline-block' &&
				contentAlign === 'center'
			) {
				css.push(`.article-${id}${device} > .canvas .${component}{
					margin-left: 50% !important;
					transform: translateX(-50%) !important;
				}`);
			}

			if (properties.css) {
				this
					.onCustomCSS(`.article-${id}${device}  > .canvas .${component} {
					${properties.css};
				}`);
			}

			textDisplay = textDisplay ? `display: ${textDisplay};` : '';

			if (backgroundColor) {
				css.push(`.article-${id}${device} > .canvas .${component}{
					background: #${backgroundColor.replace('#', '')};
				}`);
			}

			if (textDisplay) {
				css.push(`
					.article-${id}${device} > .canvas .${component} {
						${textDisplay}
					}
				`);
			}

			css.push(`.article-${id}${device} > .canvas .${component} p {
				${device === 'mobile' ? 'margin: 0px;' : ''}
				${device === 'mobile' ? 'max-width: 100%;' : ''}
			}
			.article-${id}${device} > .canvas .${component} > sub {
				font-size: 65%;
				line-height: 0;
				vertical-align: sub;
			}
			.article-${id}${device} > .canvas .${component} > sup{
				font-size: 65%;
				line-height: 0;
				vertical-align: super;
			}
			.article-${id}${device} > .canvas .${component} i,
			.article-${id}${device} > .canvas .${component} b {
				display: inline;
			}
			.article-${id}${device} > .canvas .${component} i {
				font-style: italic;
			}`);

			fontFamily = fontFamily ? `font-family: ${fontFamily};` : '';

			if (fontSize && unit && unit['fontSize'] === 'pt') {
				fontSize =
					fontSize !== undefined
						? `font-size: ${fontSize * PT_TO_EM_FACTOR}em;`
						: '';
			} else {
				fontSize =
					fontSize !== undefined
						? `font-size: ${fontSize}${unit['fontSize']};`
						: '';
			}
			if (color && typeof color === 'string') {
				color = color ? `color: #${color.replace('#', '')};` : '';
			} else {
				color = '';
			}

			fontWeight = fontWeight ? `font-weight: ${fontWeight};` : '';
			fontStyle = fontStyle ? `font-style: ${fontStyle};` : '';
			textDecoration = textDecoration
				? `text-decoration: ${textDecoration};`
				: '';
			if (lineHeight && unit && unit['lineHeight'] === 'pt') {
				lineHeight =
					lineHeight !== undefined
						? `line-height: ${
								(lineHeight * PT_TO_EM_FACTOR_LINE_HEIGHT) / 1.6
						  }rem;`
						: '';
			} else {
				lineHeight =
					lineHeight !== undefined
						? `line-height: ${lineHeight}${unit['lineHeight']};`
						: '';
			}

			textTransform = textTransform
				? `text-transform: ${textTransform};`
				: '';
			textAlign = textAlign ? `text-align: ${textAlign};` : '';
			if (letterSpacing && unit && unit['letterSpacing'] === 'pt') {
				letterSpacing =
					letterSpacing !== undefined
						? `letter-spacing: ${
								parseFloat(letterSpacing) * PT_TO_EM_FACTOR
						  }em;`
						: '';
			} else {
				letterSpacing =
					letterSpacing !== undefined
						? `letter-spacing: ${parseFloat(letterSpacing)}${
								unit['letterSpacing']
						  };`
						: '';
			}
			marginBottom =
				marginBottom !== undefined
					? `margin-bottom: ${marginBottom}${unit['marginBottom']};`
					: '';
			marginTop =
				marginTop !== undefined
					? `margin-top: ${marginTop}${unit['marginTop']};`
					: '';
			marginLeft =
				marginLeft !== undefined
					? `margin-left: ${marginLeft}${unit['marginLeft']};`
					: '';
			marginRight =
				marginRight !== undefined
					? `margin-right: ${marginRight}${unit['marginRight']};`
					: '';
			paragraphSpacing = paragraphSpacing
				? `margin-bottom: ${paragraphSpacing}${unit['paragraphSpacing']};`
				: '';
			dropcapLineHeight =
				dropcapLineHeight !== undefined
					? `line-height: ${dropcapLineHeight}${unit['dropcapLineHeight']};`
					: '';
			dropcapFontFamily = dropcapFontFamily
				? `font-family: ${dropcapFontFamily};`
				: '';
			if (dropcapColor && typeof dropcapColor === 'string') {
				dropcapColor = dropcapColor
					? `color: #${dropcapColor.replace('#', '')};`
					: '';
			} else {
				dropcapColor = '';
			}

			if (dropcapFontSize && unit && unit['dropcapFontSize'] === 'pt') {
				dropcapFontSize = dropcapFontSize
					? `font-size: ${parseFloat(
							dropcapFontSize * PT_TO_EM_FACTOR
					  )}em;`
					: 'font-size: 4em;';
			} else {
				dropcapFontSize = dropcapFontSize
					? `font-size: ${parseFloat(dropcapFontSize)}${
							unit['dropcapFontSize']
					  };`
					: 'font-size: 4em;';
			}
			dropcapPaddingTop =
				dropcapPaddingTop !== undefined
					? `padding-top: ${dropcapPaddingTop}${unit['dropcapPaddingTop']};`
					: '';
			dropcapPaddingBottom =
				dropcapPaddingBottom !== undefined
					? `padding-bottom: ${dropcapPaddingBottom}${unit['dropcapPaddingBottom']};`
					: '';
			dropcapPaddingRight =
				dropcapPaddingRight !== undefined
					? `padding-right: ${dropcapPaddingRight}${unit['dropcapPaddingRight']};`
					: '';
			dropcapPaddingLeft =
				dropcapPaddingLeft !== undefined
					? `padding-left: ${dropcapPaddingLeft}${unit['dropcapPaddingLeft']};`
					: '';
			dropcapMarginTop =
				dropcapMarginTop !== undefined
					? `margin-top: ${dropcapMarginTop}${unit['dropcapMarginTop']};`
					: '';
			dropcapMarginBottom =
				dropcapMarginBottom !== undefined
					? `margin-bottom: ${dropcapMarginBottom}${unit['dropcapMarginBottom']};`
					: '';
			dropcapMarginLeft =
				dropcapMarginLeft !== undefined
					? `margin-left: ${dropcapMarginLeft}${unit['dropcapMarginLeft']};`
					: '';
			dropcapMarginRight =
				dropcapMarginRight !== undefined
					? `margin-right: ${dropcapMarginRight}${unit['dropcapMarginRight']};`
					: '';
			if (
				dropcapBackgroundColor &&
				typeof dropcapBackgroundColor === 'string'
			) {
				dropcapBackgroundColor = dropcapBackgroundColor
					? `background: #${dropcapBackgroundColor.replace('#', '')};`
					: '';
			} else {
				dropcapBackgroundColor = '';
			}

			paddingTop =
				paddingTop !== undefined
					? `padding-top: ${paddingTop}${unit['paddingTop']};`
					: '';
			paddingBottom =
				paddingBottom !== undefined
					? `padding-bottom: ${paddingBottom}${unit['paddingBottom']};`
					: '';
			paddingLeft =
				paddingLeft !== undefined
					? `padding-left: ${paddingLeft}${unit['paddingLeft']};`
					: '';
			paddingRight = paddingRight
				? `padding-right: ${paddingRight}${unit['paddingRight']};`
				: '';

			if (textIndent && unit && unit['textIndent'] === 'pt') {
				textIndent = textIndent
					? `text-indent: ${textIndent * PT_TO_EM_FACTOR}em;`
					: '';
			} else {
				textIndent = textIndent
					? `text-indent: ${textIndent}${unit['textIndent']};`
					: '';
			}

			width = width
				? `width: ${width}${unit['width'] || '%'};`
				: 'width: 100%';

			switch (contentAlign) {
				case 'left':
					contentAlign = `align-items: flex-start;`;
					break;
				default:
					contentAlign = 'align-items: center;';
			}

			let border = [];
			if (borderType) {
				border.push(`border-style: ${borderType};`);
			}
			if (borderColor && typeof borderColor === 'string') {
				border.push(`border-color: #${borderColor.replace('#', '')};`);
			}
			if (borderWidth && borderEdge) {
				for (const edge of borderEdge.split('-')) {
					border.push(
						`border-${edge}-width: ${borderWidth}${unit['borderWidth']};`
					);
				}
			}

			if (borderRadius) {
				border.push(
					`border-radius: ${borderRadius}${
						unit['borderRadius'] || 'pt'
					};`
				);
			}

			border = border.join('\n');

			if (component !== 'imagecaption') {
				css.push(`.article-${id}${device} > .canvas .${component} {
					flex-direction: column;
					justify-content: center;
					${contentAlign};
					${border}
				}`);
			}

			css.push(`.article-${id}${device} > .canvas .${component} {
				overflow-wrap: break-word;
				${textDisplay}
				${marginBottom}
				${marginTop}
				${marginLeft}
				${marginRight}
				${paddingTop}
				${paddingBottom}
				${paddingLeft}
				${paddingRight}
				${fontFamily}
				${color}
				${fontWeight}
				${fontStyle}
				${textDecoration}
				${textTransform}
				${textAlign}
				${letterSpacing}
				${textIndent}
			}`);

			css.push(`.article-${id}${device} > .canvas .${component} > p,
			.article-${id}${device} > .canvas .${component} > ul,
			.article-${id}${device} > .canvas .${component} > ol,
			.article-${id}${device} > .canvas .${component} > div,
			.article-${id}${device} > .canvas .${component} > span {
				${fontSize}
				${lineHeight}
			}`);

			// p:last-of-type

			css.push(`.article-${id}${device} .${component} p:last-of-type {
				margin-bottom: 0px !important;
			}`);

			css.push(`.article-${id}${device} > .canvas .${component} p {
				overflow-wrap: break-word;
				${paragraphSpacing}
				${textIndent}
				${width}
			}`);

			css.push(`.article-${id}${device}  > .canvas .${component}.dropcap > :first-child::first-letter {
				float: left;
				${dropcapFontSize}
				${dropcapFontFamily}
				${dropcapLineHeight}
				${dropcapPaddingTop}
				${dropcapPaddingBottom}
				${dropcapPaddingRight}
				${dropcapPaddingLeft}
				${dropcapMarginTop}
				${dropcapMarginBottom}
				${dropcapMarginLeft}
				${dropcapMarginRight}
				${dropcapColor}
				${dropcapBackgroundColor}
			}`);
			if (fontFamilyBold) {
				css.push(`.article-${id}${device}  > .canvas .${component} b {
					font-family: ${fontFamilyBold} !important;
					font-weight: normal;
				}`);
			}

			if (fontFamilyItalic) {
				css.push(`.article-${id}${device} > .canvas .${component} i,
					.article-${id}${device} > .canvas .${component} em {
					font-family: ${fontFamilyItalic};
					font-style: normal;
				}`);
			}

			if (fontFamilyBoldItalic) {
				css.push(`.article-${id}${device} > .canvas .${component} b i,
					.article-${id}${device} > .canvas .${component} b em {
					font-family: ${fontFamilyBoldItalic};
					font-weight: normal;
					font-style: normal;
				}`);
			}

			if (anchor_color) {
				css.push(`.article-${id}${device} > .canvas .${component} a {
					color: #${anchor_color.replace('#', '')};
				}`);
			}
			if (anchor_background_color) {
				css.push(`.article-${id}${device} > .canvas .${component} a {
					background: #${anchor_background_color.replace('#', '')};
				}`);
			}
			if (anchor_font_style && anchor_font_style !== 'inherit') {
				css.push(`.article-${id}${device} > .canvas .${component} a {
					font-style: ${anchor_font_style};
				}`);
			}
			if (anchor_font_weight && anchor_font_weight !== 'inherit') {
				css.push(`.article-${id}${device} > .canvas .${component} a {
					font-weight: ${anchor_font_weight};
				}`);
			}
			if (
				anchor_text_decoration &&
				anchor_text_decoration !== 'inherit'
			) {
				css.push(`.article-${id}${device} > .canvas .${component} a {
					text-decoration: ${anchor_text_decoration};
				}`);
			}
			if (anchor_text_decoration_color) {
				css.push(`.article-${id}${device} > .canvas .${component} a {
					text-decoration-color: #${anchor_text_decoration_color.replace('#', '')};
				}`);
			}
		}
		return css.join('\n');
	};

	getAnchorCSS = (style, device) => {
		const id = this.id;
		device = device !== 'mobile' ? `.${device}` : '';
		if (!style?.properties?.anchor) {
			return '';
		}
		let { fontStyle, color, fontWeight, textDecoration, textTransform } =
			style.properties['anchor'];

		color = color ? `color: #${color.replace('#', '')};` : '';
		fontStyle = fontStyle ? `font-style: ${fontStyle};` : '';
		fontWeight = fontWeight ? `font-weight: ${fontWeight};` : '';
		textDecoration = textDecoration
			? `text-decoration: ${textDecoration};`
			: '';
		textTransform = textTransform
			? `text-transform: ${textTransform};`
			: '';

		return `.article-${id}${device} > .canvas a {
			${color}
			${fontStyle}
			${fontWeight}
			${textDecoration}
			${textTransform}
		}`;
	};

	getSpacerCSS = (style, device) => {
		const id = this.id;
		device = device !== 'mobile' ? ` .${device}` : '';
		if (!style?.properties?.spacer) {
			return '';
		}
		const css = [];
		let {
			vSmallHeight,
			smallHeight,
			mediumHeight,
			largeHeight,
			vLargeHeight,
			unit,
		} = style.properties['spacer'];

		if (!unit) {
			return css.join('\n');
		}

		if (vSmallHeight !== undefined) {
			css.push(`.article-${id}${device} > .canvas .spacer.v-small {
				height: ${vSmallHeight}${unit['vSmallHeight'] || 'px'};
			}`);
		}
		if (smallHeight !== undefined) {
			css.push(`.article-${id}${device} > .canvas .spacer.small {
				height: ${smallHeight}${unit['smallHeight'] || 'px'};
			}`);
		}
		if (mediumHeight !== undefined) {
			css.push(`.article-${id}${device} > .canvas .spacer.medium {
				height: ${mediumHeight}${unit['mediumHeight'] || 'px'};
			}`);
		}
		if (largeHeight !== undefined) {
			css.push(`.article-${id}${device} > .canvas .spacer.large {
				height: ${largeHeight}${unit['largeHeight'] || 'px'};
			}`);
		}
		if (vLargeHeight !== undefined) {
			css.push(`.article-${id}${device} > .canvas .spacer.v-large {
				height: ${vLargeHeight}${unit['vLargeHeight'] || 'px'};
			}`);
		}

		return css.join('\n');
	};
}

function clone(obj) {
	if (!obj) return obj;
	if (structuredClone) return structuredClone(obj);
	return JSON.parse(JSON.stringify(obj));
}
